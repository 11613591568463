import ViewHome from '../views/ViewHome'

export default [
  {
    path: '*',
    name: 'home',
    component: ViewHome
  },

  // Make sure we avoid any redirect
  {
    path: '/login',
    name: 'login',
    component: ViewHome
  }
]
