<template>
  <layout>
    <template #main>
      <div class="mx-auto max-w-md h-full flex flex-col gap-5">
        <div v-if="view === 'list'" class="flex flex-col gap-10 flex-1">
          <div>
            <fw-heading>Serviços disponíveis</fw-heading>
            <fw-label>Escolha o serviço que pretende configurar neste dispositivo:</fw-label>
          </div>
          <div class="flex flex-col gap-5">
            <fw-button type="light" size="lg" expanded @click.native="view = 'config-employee-time-clock'">
              Marcação de assiduidade
            </fw-button>
          </div>
          <div class="flex flex-col gap-5">
            <fw-button type="light" size="lg" expanded @click.native="autoConfigAgenda()">
              Agenda de evento
            </fw-button>
          </div>
          <div class="flex flex-col gap-5">
            <fw-button type="light" size="lg" expanded @click.native="view = 'event-checkin'">
              Marcação em evento
            </fw-button>
          </div>
          <div class="flex flex-col gap-5">
            <fw-button type="light" size="lg" expanded @click.native="view = 'config-signage'">
              Divulgação (Signage)
            </fw-button>
          </div>
        </div>
        <view-configuration-employee-time-clock v-else-if="view === 'config-employee-time-clock'" class="flex-1" />
        <view-configuration-event-checkin v-else-if="view === 'event-checkin'" class="flex-1" />
        <view-configuration-signage v-else-if="view === 'config-signage'" class="flex-1" />
        <div v-if="view !== 'list'">
          <fw-button type="link-muted" expanded @click.native="view = 'list'">
            Voltar à lista de serviços
          </fw-button>
        </div>
      </div>
    </template>
  </layout>
</template>

<script>
import Layout from '@/components/Layout.vue'
import ViewConfigurationEmployeeTimeClock from './ViewConfigurationEmployeeTimeClock.vue'
import ViewConfigurationSignage from './ViewConfigurationSignage.vue'
import ViewConfigurationEventCheckin from './ViewConfigurationEventCheckin.vue'
export default {
  name: 'ViewConfiguration',

  components: {
    Layout,
    ViewConfigurationEmployeeTimeClock,
    ViewConfigurationSignage,
    ViewConfigurationEventCheckin
  },

  data() {
    return {
      view: 'list'
    }
  },
  methods: {
    autoConfigAgenda() {
      // Use on boot auto config (check App.vue)
      this.$router.go()
    }
  }
}
</script>
