import Api from '@/fw-modules/fw-core-vue/api/Api'
import FwEnvConfig from '@/fw-modules/fw-core-vue/config'

export default {
  base(config) {
    let api = Api(config)
    api.defaults.baseURL = FwEnvConfig.apiUrlContent
    return api
  },

  baseUrl() {
    return FwEnvConfig.apiUrlContent
  },
  //activate entry registration tablet
  async activateEventsTablet(activation_key, public_key, reference_key) {
    const response = await this.base().post(`/v1/devices/activate/${activation_key}`, {
      public_key: public_key,
      reference_key: reference_key
    })
    return response.data
  },
  async registerEventsTablet(event_code, session_key) {
    const response = await this.base().post(`/v1/attendance/events/${event_code}`, {
      session_key: session_key
    })
    return response.data
  },
  async getEventInformation(event_code) {
    const response = await this.base().get(`/v1/attendance/events/${event_code}`)
    return response.data
  },
  async getEventSessions(event_code, page = 1, limit = 5) {
    const response = await this.base().get(`/v1/attendance/events/${event_code}/sessions`, {
      params: {
        page: page,
        limit: limit
      }
    })
    return response.data
  }
}
