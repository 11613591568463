var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isFuture || _vm.isHappening)?_c('div',{staticClass:"flex py-4 gap-10",class:{
    'opacity-50': !_vm.isFuture && !_vm.isHappening,
    'is-happening': _vm.isHappening,
    'is-future': _vm.isFuture
  }},[_c('div',{staticClass:"text-3xl font-bold",class:{
      'opacity-50': !_vm.isHappening
    }},[_vm._v(" "+_vm._s(_vm.startTime)+" ")]),_c('div',{staticClass:"border-b flex-1 pb-8 gap-2 flex flex-col relative",class:{
      'border-transparent': _vm.isHappening,
      'border-white/40': !_vm.isHappening
    }},[_c('div',{staticClass:"flex w-full"},[_c('div',{staticClass:"flex flex-col gap-5 flex-1"},[_c('div',{staticClass:"text-4xl font-extrabold",class:{
            'text-green-agenda': _vm.isHappening
          },domProps:{"innerHTML":_vm._s(_vm.title)}}),(_vm.location)?_c('div',{staticClass:"text-white/70 flex gap-1.5 items-center"},[_c('div',{staticClass:"flex-1 text-2xl"},[_vm._v(_vm._s(_vm.location))])]):_vm._e(),(_vm.showSpeakers)?_c('div',{staticClass:"flex flex-col gap-5 mt-2"},_vm._l((_vm.speakers),function(speaker,s){return _c('div',{key:'speaker' + s,staticClass:"flex gap-4"},[_c('div',[(speaker.photo)?_c('img',{staticClass:"rounded-full w-14 h-14 overflow-hidden",attrs:{"src":speaker.photo}}):_c('svg',{staticClass:"h-12 w-12 opacity-80 flex-shrink-0",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","stroke-width":"2","stroke":"currentColor","fill":"none","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('path',{attrs:{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}}),_c('path',{attrs:{"d":"M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"}}),_c('path',{attrs:{"d":"M12 10m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"}}),_c('path',{attrs:{"d":"M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855"}})])]),_c('div',{staticClass:"flex-1 flex justify-center flex-col"},[_c('div',{staticClass:"font-bold text-2xl"},[_vm._v(_vm._s(speaker.name))]),_c('div',{staticClass:"text-xl text-white/50"},[_vm._v(" "+_vm._s(speaker.job)+_vm._s(speaker.job && speaker.institution ? ' - ' : '')+_vm._s(speaker.institution)+" ")])])])}),0):_vm._e()]),_c('div',{staticClass:"text-3xl font-semibold opacity-50 w-28 text-right"},[_vm._v(_vm._s(_vm.duration))])]),(_vm.isHappening)?_c('div',{staticClass:"progress w-full absolute bottom-0 left-0 right-0 h-1.5 bg-white/20"},[_c('div',{staticClass:"h-1.5",style:({ width: _vm.progress + '%', backgroundColor: '#64EFAB' })})]):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }