<template>
  <div class="flex flex-col gap-5 h-full">
    <div v-if="!isActivated" class="flex flex-col gap-5 h-full">
      <div>
        <fw-heading size="h1" muted>Ativar serviço de marcação em evento</fw-heading>
        <fw-label class="my-3">Código de ativação</fw-label>
        <b-input v-model="activationCode" size="is-medium" :disabled="loading" placeholder="Código de Ativação" />
        <fw-label class="my-3">Referência Inventário Tablet</fw-label>
        <b-input v-model="reference_key" size="is-medium" :disabled="loading" placeholder="Código de Inventário" />
      </div>
      <div class="flex-1"></div>
      <div>
        <fw-button
          :type="canActivate ? 'primary' : 'disabled'"
          :loading="loading"
          size="lg"
          expanded
          :disabled="!canActivate"
          @click.native="activateDevice"
          >Ativar serviço</fw-button
        >
      </div>
    </div>
    <div v-else class="text-center flex flex-col gap-10">
      <div class="text-primary font-bold text-xl">Serviço ativado</div>
      <div class="text-gray-500">{{ accessKey }}</div>
      <div>
        <fw-button type="link-muted" @click.native="resetDevice">Reset device</fw-button>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceContent from '@/services/ServiceContent'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  name: 'ViewConfigurationEventCheckin',

  data() {
    return {
      loading: false,
      activationCode: null,
      reference_key: null
    }
  },

  computed: {
    accessKey() {
      return localStorage.getItem('access-key')
    },
    isActivated() {
      return localStorage.getItem('device-mode') === 'event-checkin' && this.accessKey
    },
    canActivate() {
      return (
        this.reference_key !== null &&
        this.reference_key.length > 1 &&
        this.activationCode !== null &&
        this.activationCode !== '' &&
        this.activationCode.length > 3
      )
    }
  },

  methods: {
    async generateKeyPair() {
      const keyPair = await window.crypto.subtle.generateKey(
        {
          name: 'ECDSA',
          namedCurve: 'P-384'
        },
        true,
        ['sign', 'verify'] // Key usages
      )
      return keyPair
    },
    async exportPublicKey(keyPair) {
      const jwkPublicKey = await window.crypto.subtle.exportKey(
        'jwk', // Format to export the key
        keyPair.publicKey
      )
      return jwkPublicKey
    },
    async exportPrivateKey(keyPair) {
      const jwkPrivateKey = await window.crypto.subtle.exportKey(
        'jwk', // Format to export the key
        keyPair.privateKey
      )
      return jwkPrivateKey
    },
    async activateDevice() {
      this.loading = true

      // Create
      let key = await this.generateKeyPair()
      let publicKey = await this.exportPublicKey(key)
      let privateKey = await this.exportPrivateKey(key)

      // Save to local storage
      localStorage.setItem('public-key', JSON.stringify(publicKey))
      localStorage.setItem('private-key', JSON.stringify(privateKey))

      utils.tryAndCatch(
        this,
        // Do the thing
        async () => {
          let result = await ServiceContent.activateEventsTablet(this.activationCode, publicKey, this.reference_key)
          console.log('activateDevice', result)
          if (result.device && result.device.key) {
            localStorage.setItem('deviceKey', result.device.key)
            localStorage.setItem('device-mode', 'event-checkin')

            // Make sure to remove /reset all other keys
            localStorage.removeItem('event-code')
            localStorage.removeItem('session-key')
            localStorage.removeItem('session')
            localStorage.removeItem('event')

            this.$router.go()
          } else {
            this.$buefy.alert.open({
              message: 'Código de ativação inválido',
              type: 'is-danger'
            })
          }
        },

        // Finally code
        () => {
          this.loading = false
        },

        // Deal with 404 / api error when activation code is invalid
        error => {
          this.$buefy.alert.open({
            message: error.message,
            type: 'is-danger'
          })
        }
      )
    },
    resetDevice() {
      this.$buefy.dialog.confirm({
        message: 'Tem a certeza que pretende limpar a configuração atual do dispositivo?',
        confirmText: 'Sim',
        cancelText: 'Não',
        type: 'is-danger',
        onConfirm: () => {
          localStorage.removeItem('access-key')
          localStorage.removeItem('device-mode')
        }
      })
    }
  }
}
</script>
