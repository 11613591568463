<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { PAGES_API_TOKEN } from '@/constants'

export default {
  name: 'App',
  data() {
    return {
      urlParams: new URLSearchParams(window.location.search)
    }
  },
  mounted() {
    // Check device mode
    let deviceMode = this.urlParams.get('device-mode')
    if (deviceMode) {
      localStorage.setItem('device-mode', deviceMode)
    }

    // Set debug mode
    this.setLocalStorageItem('debug', 'false', true)

    // Predefined configurations for each device mode
    if (deviceMode == 'agenda') {
      // Set token for pages api
      localStorage.setItem('pages-token', PAGES_API_TOKEN[deviceMode])

      // Get screen key from url params or keep the one in local storage
      this.setLocalStorageItem('page-id')
      this.setLocalStorageItem('blocks-id')
      this.setLocalStorageItem('footer-url', 'https://www.uc.pt')
      this.setLocalStorageItem('update-time', 50000)
      this.setLocalStorageItem('carrousel-time', 0)

      // Redirect to home
      // window.location.href = '/'
    } else if (deviceMode == 'signage') {
      // Set token for pages api
      localStorage.setItem('pages-token', PAGES_API_TOKEN[deviceMode])
      this.setLocalStorageItem('screen-key')
      this.setLocalStorageItem('online-only', 'false', true)
    } else if (deviceMode == 'employee-time-clock') {
      this.setLocalStorageItem('access-key')
    }
  },
  methods: {
    // Helper function to set local storage items
    setLocalStorageItem(key, defaultValue, removeIfExists = false) {
      if (removeIfExists && localStorage.getItem(key)) {
        localStorage.removeItem(key)
      }

      const value = this.urlParams.get(key) || localStorage.getItem(key) || defaultValue
      localStorage.setItem(key, value)
    }
  }
}
</script>
