import Vue from 'vue'
import VueRouter from 'vue-router'

import BaseRoutes from './routes'
import CoreRouter from '@/fw-modules/fw-core-vue/router'

Vue.use(VueRouter)

const routes = [].concat(BaseRoutes)

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_ROUTER_BASE || '/',
  routes
})

router.beforeResolve((to, from, next) => {
  return CoreRouter.beforeResolve(to, from, next)
})
router.afterEach((to, from, next) => {
  return CoreRouter.afterEach(to, from, next)
})
router.beforeEach((to, from, next) => {
  return CoreRouter.beforeEach(to, from, next)
})

export default router
