<template>
  <div class="flex flex-col gap-5 h-full">
    <div v-if="!isActivated" class="flex flex-col gap-5 h-full">
      <div>
        <fw-heading size="h1" muted>Ativar serviço de divulgação</fw-heading>
        <fw-label class="my-3">Screen Key (Token from UC Pages)</fw-label>
        <b-input v-model="screenKey" size="is-medium" :disabled="loading" placeholder="Token da UC Pages" />
      </div>
      <div class="flex-1"></div>
      <div>
        <fw-button
          :type="canActivate ? 'primary' : 'disabled'"
          :loading="loading"
          size="lg"
          expanded
          :disabled="!canActivate"
          @click.native="activateDevice"
          >Ativar serviço</fw-button
        >
      </div>
    </div>
    <div v-else class="text-center flex flex-col gap-10">
      <div class="text-primary font-bold text-xl">Serviço ativado</div>
      <div class="text-gray-500">Screen Key: ...{{ screenKey[-5] }}</div>
      <div>
        <fw-button type="link-muted" @click.native="resetDevice">Reset device</fw-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ViewConfigurationSignage',

  data() {
    return {
      loading: false,
      eventKey: null,
    }
  },

  computed: {
    localScreenKey() {
      return localStorage.getItem('screen-key')
    },
    isActivated() {
      return localStorage.getItem('device-mode') === 'signage' && this.localScreenKey
    },
    canActivate() {
      return !this.screenKey
    },
  },

  methods: {
    async activateDevice() {
      if (!this.canActivate) return
      localStorage.setItem('screen-key', this.screenKey)
      localStorage.setItem('device-mode', 'signage')
      this.$router.go()
    },
    resetDevice() {
      this.$buefy.dialog.confirm({
        message: 'Tem a certeza que pretende eliminar a configuração atual deste dispositivo?',
        confirmText: 'Sim',
        cancelText: 'Não',
        type: 'is-danger',
        onConfirm: () => {
          localStorage.removeItem('screen-key')
          localStorage.removeItem('device-mode')
          this.$router.go()
        },
      })
    },
  },
}
</script>
