<template>
  <layout :footer="false" screen>
    <template #main>
      <div
        v-if="!playlist.current.hash || loading.initial || (error && !silenceError) || !playlist.current.slides.length"
        class="flex flex-col justify-center items-center w-full h-full absolute top-0 left-0 z-0 gap-10"
      >
        <div class="pt-32">
          <fw-icon-uc-digital-building
            class="w-96 h-96"
            :class="{
              'text-white': !error,
              'text-red-500': error
            }"
          />
        </div>
        <div class="h-32">
          <div class="text-3xl font-semibold text-white text-center">
            <div v-if="loading.initial" class="flex flex-col gap-2">
              <div>Loading...</div>
              <div v-if="loading.screenData" class="text-2xl text-gray-400">Fetching screen data...</div>
              <div v-if="loading.downloadFiles" class="text-2xl text-gray-400">Downloading files...</div>
            </div>
            <div v-else-if="error" class="text-red-500">
              <div>{{ error }}</div>
              <div class="text-2xl text-gray-400">{{ errorDescription }}</div>
            </div>
            <div v-if="!loading.initial && currentPlaylistIsEmpty" class="text-xl text-gray-400">Playlist is empty</div>
          </div>
        </div>
        <footer class="absolute right-6 bottom-5 text-xs text-gray-400">v.{{ appVersion }}</footer>
      </div>
      <div v-else class="slider-container w-full h-full absolute top-0 left-0 z-0">
        <div
          v-for="(slide, index) in playlist.current.slides"
          :key="index"
          :ref="`slide-${index}`"
          class="slide absolute top-0 left-0 w-full h-full flex justify-center items-center"
        >
          <img
            v-if="slide.type === 'pages-image'"
            v-show="currentSlideIndex === index"
            :src="slide.blob || slide.url"
            alt="Signage Image"
            class="signage-image"
          />
          <video
            v-if="currentSlideIndex === index && slide.type === 'pages-video'"
            ref="videoPlayer"
            width="100%"
            height="auto"
            :src="slide.blob || slide.url"
            autoplay
            muted
            class="signage-video"
            @ended="onVideoEnd"
          >
            Your browser does not support the video tag.
          </video>
          <iframe
            v-if="slide.type === 'youtube-video'"
            v-show="currentSlideIndex === index"
            :id="`youtube-player-${index}`"
            :src="
              `${slide.url}?mute=1&controls=0&modestbranding=1&showinfo=0&rel=0&fs=0&cc_load_policy=0&iv_load_policy=1&loop=0&playsinline=1&enablejsapi=1`
            "
            allowtransparency
            frameborder="0"
            class="signage-youtube w-full h-full opacity-0"
          ></iframe>
        </div>
      </div>
      <div
        v-if="debugMode"
        class="absolute bottom-0 right-0 p-5 bg-opacity-90 bg-white text-xs gap-5 flex flex-col debug-panel overflow-auto"
      >
        <div class="flex gap-5">
          <div>
            <fw-label>Current Slide Index</fw-label>
            <pre>{{ currentSlideIndex }}</pre>
          </div>
          <div>
            <fw-label>IndexedDB Supported</fw-label>
            <pre>{{ isIndexedDbSupported }}</pre>
          </div>
          <div>
            <fw-label>Loading screen data</fw-label>
            <pre>{{ loading.screenData }}</pre>
          </div>
          <div>
            <fw-label>Downloading files</fw-label>
            <pre>{{ loading.downloadFiles }}</pre>
          </div>
          <div>
            <fw-label>Error</fw-label>
            <pre>{{ error || 'No error' }} {{ errorDescription }}</pre>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-5">
          <div>
            <div>
              <fw-label>Screen</fw-label>
              <pre>{{ screen }}</pre>
            </div>
          </div>
          <div>
            <div>
              <fw-label>Playlist</fw-label>
              <pre>{{ playlist }}</pre>
            </div>
            <div>
              <fw-label>IDB Stored Files</fw-label>
              <pre>{{ idbFiles }}</pre>
            </div>
          </div>
        </div>
      </div>
      <div class="absolute right-10 top-10 flex gap-5 text-xs items-center">
        <div v-if="error" class="flex gap-3 items-center">
          <div class="opacity-50">{{ error }} {{ errorDescription }}</div>
          <div><fw-icon-error-warning class="w-5 h-5" /></div>
        </div>
        <div v-if="loading.downloadFiles">
          <fw-icon-download class="w-5 h-5" />
        </div>
        <div v-if="loading.screenData || loading.downloadFiles">
          <fw-icon-loading class="w-6 h-6" />
        </div>
      </div>
    </template>
  </layout>
</template>

<script>
import { openDB } from 'idb'
import Layout from '@/components/Layout.vue'
import ServiceUCPages from '@/fw-modules/fw-core-vue/utilities/services/ServiceUCPages.js'

export default {
  name: 'ViewSignage',

  components: {
    Layout
  },

  data() {
    return {
      loading: {
        initial: true,
        screenData: false,
        downloadFiles: false
      },
      screen: {},
      playlist: {
        current: {
          hash: null,
          slides: []
        },
        next: {
          hash: null,
          slides: []
        }
      },

      sliderInterval: 5000, // Interval in milliseconds to change slides
      screenDataInterval: parseInt(new URLSearchParams(window.location.search).get('dataInterval')) || 300000,

      youtubeVideoPlayers: {}, // Youtube video players

      currentSlideIndex: 0,
      fetchScreenIntervalId: null, // ID of the interval for fetching screen data
      sliderIntervalId: null, // ID of the interval for the carousel

      error: null,
      errorDescription: null,
      silenceError: false,

      idbFiles: [], // List of files in IndexedDB (for debugging)

      debugMode: localStorage.getItem('debug') === 'true',
      onlineOnly: localStorage.getItem('online-only') === 'true',
      ucpages_api_url: process.env.VUE_APP_UCPAGES_API,

      appVersion: process.env.VUE_APP_VERSION
    }
  },

  computed: {
    // Get the current slide from the playlist
    currentSlide() {
      return this.playlist.current.slides[this.currentSlideIndex]
    },
    currentPlaylistIsEmpty() {
      return this.playlist.current.slides.length === 0
    },
    isIndexedDbSupported() {
      return 'indexedDB' in window
    }
  },

  // Watch for changes in currentSlideIndex and if it is a YoutTube video, create the player and start the video
  watch: {
    currentSlideIndex(newIndex, oldIndex) {
      // Check if the current slide is an image or video
      // const lastSlide = this.playlist.current.slides[oldIndex]
      const nextSlide = this.playlist.current.slides[newIndex]

      // Deal with opacity
      const lastSlideElement = this.$refs[`slide-${oldIndex}`][0]
      const nextSlideElement = this.$refs[`slide-${newIndex}`][0]
      if (lastSlideElement) {
        lastSlideElement.style.opacity = 0
      }
      if (nextSlideElement) {
        nextSlideElement.style.opacity = 1
      }

      if (nextSlide.type && nextSlide.type === 'youtube-video') {
        // Check if youtubeVideoPlayers is not empty or return
        if (Object.keys(this.youtubeVideoPlayers).length === 0) return

        // Get the last and next player from slide index
        // const lastPlayer = this.youtubeVideoPlayers[`slide-${oldIndex}`]
        const nextPlayer = this.youtubeVideoPlayers[`slide-${newIndex}`]

        // If the next player exists, play the video
        if (nextPlayer) {
          nextPlayer.playVideo()
        }
      }
    }
  },

  async mounted() {
    await this.fetchScreenData()

    // Set interval to fetch screen data every {screenDataInterval} milliseconds
    console.log('Setting interval to fetch screen data every', this.screenDataInterval, 'ms')
    this.fetchScreenIntervalId = setInterval(this.fetchScreenData, this.screenDataInterval)
  },

  beforeDestroy() {
    // Clear interval when the component is destroyed
    clearInterval(this.fetchScreenIntervalId)
    clearInterval(this.sliderIntervalId)
  },

  methods: {
    async fetchScreenData() {
      console.log('Fetching screen data...')
      this.loading.screenData = true

      try {
        this.screen = await ServiceUCPages.getScreen(localStorage.getItem('screen-key'))

        console.log('Screen data:', this.screen)

        // Make a hash of the screen data to compare with the previous one
        this.screen.versionHash = await this.makeScreenVersionHash(this.screen)

        // Update the interval to change slides
        this.sliderInterval = parseInt(this.screen.slider_duration) * 1000 // Convert seconds to milliseconds
        console.log('Setting slider interval to', this.sliderInterval, 'ms')

        if (this.screen.versionHash !== this.playlist.current.hash) {
          if (this.loading.initial) {
            console.log('First time fetching screen data')
          } else {
            console.log('Screen data has changed. The playlist will be replaced after the last slide ends.')
          }

          // If slides type is pages-image or pages-video,
          // make sure we replace base url from ucpages.uc.pt to www.uc.pt to use Varnish cache
          if (this.ucpages_api_url && this.ucpages_api_url.startsWith('https://ucpages.uc.pt')) {
            this.screen.playlist.forEach(slide => {
              if (slide.type === 'pages-image' || slide.type === 'pages-video') {
                slide.url = slide.url.replace('https://ucpages.uc.pt', 'https://www.uc.pt')
              }
            })
          }

          // Make sure we save every slide's file URL in IndexedDB
          if (!this.onlineOnly && this.isIndexedDbSupported) {
            for (const slide of this.screen.playlist) {
              if (slide.type === 'pages-image' || slide.type === 'pages-video') {
                // Remove the base URL from the file URL (just for local development - use /api, our local proxy)
                if (this.ucpages_api_url && !this.ucpages_api_url.startsWith('https://ucpages.uc.pt')) {
                  slide.url = slide.url.replace('https://ucpages.uc.pt', '')
                }
                slide.blob = await this.saveFileToIDB(slide.url, slide.type)
              }
            }
          }
          // Set playlist as current (if it's the first time or playlist is empty) or next (if it's not the first time)
          if (
            this.playlist.current.hash === null ||
            (this.playlist.current.hash && this.playlist.current.slides.length === 0)
          ) {
            this.playlist.current.hash = this.screen.versionHash
            this.playlist.current.slides = this.screen.playlist
          }
          // Set new playlist as new, to be loaded after the current one finishes
          else {
            this.playlist.next.hash = this.screen.versionHash
            this.playlist.next.slides = this.screen.playlist
          }
        } else {
          console.log('Screen data has not changed')
        }

        if (this.debugMode) {
          this.idbFiles = await this.getIDBFiles()
        }

        // Clean files from IndexedDB that are not in the new playlist current or next
        this.cleanIDBFiles()

        setTimeout(() => {
          // Turn off initial loading
          this.loading.initial = false
          // Load and start the playlist
          this.loadAndStartPlaylist()
        }, 1500)

        // Reset error state
        this.error = null
        this.errorDescription = null
      } catch (error) {
        console.error('Error fetching screen data:', error)

        // If we got screen and playlist.current, keep the current playlist and do not show the error
        if (this.screen && this.playlist.current.hash) {
          this.silenceError = true
        }

        this.error = 'Error fetching screen data'
        this.errorDescription = `[${error?.message}
          ${error?.response?.data?.error}
          ${error?.response?.data?.errorDescription}]`
        this.loading.initial = false
      } finally {
        this.loading.screenData = false
      }
    },
    // Make a hash of the screen data to compare with the previous one
    async makeScreenVersionHash(screen) {
      const screenData = JSON.stringify(screen)
      // Make a hash (using crypto) of the screen data to compare with the previous one
      if (crypto && crypto.subtle) {
        const screenHashBuffer = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(screenData))
        const screenVersionHash = Array.from(new Uint8Array(screenHashBuffer))
          .map(b => b.toString(16).padStart(2, '0'))
          .join('')
        console.log('Screen hash:', screenVersionHash)
        return screenVersionHash
      }

      // Fallback to a simple hash function
      else {
        let hash = 0
        for (let i = 0, len = screenData.length; i < len; i++) {
          let chr = screenData.charCodeAt(i)
          hash = (hash << 5) - hash + chr
          hash |= 0 // Convert to 32bit integer
        }
        return Math.abs(hash)
      }
    },
    loadAndStartPlaylist() {
      if (this.sliderIntervalId) {
        clearInterval(this.sliderIntervalId)
      }

      if (this.playlist.current.slides.length === 0) {
        console.info('No slides in the playlist')
        return
      }

      this.playlist.current.slides.forEach((slide, index) => {
        if (slide.type === 'youtube-video') {
          // Extract the video ID from the URL (youtube-nocookie.com/embed/VIDEO_ID)
          const videoId = slide.url.split('/').pop()
          // Check if the videoId is a valid youtube video ID
          if (!videoId.length === 11) {
            console.error('Invalid Youtube video Id:', videoId)
            // Remove the slide from the playlist
            this.playlist.current.slides.splice(index, 1)
            // Move to the next slide
            return
          }
          this.createYouTubeVideoPlayer(videoId, index)
        }
      })

      this.sliderIntervalId = setInterval(() => {
        const currentSlide = this.playlist.current.slides[this.currentSlideIndex]
        if (currentSlide.type === 'pages-image') {
          this.currentSlideIndex = (this.currentSlideIndex + 1) % this.playlist.current.slides.length
          // Replace playlist if needed
          if (this.currentSlideIndex === 0 && this.playlist.next.hash) {
            this.replaceCurrentPlaylist()
          }
        }
      }, this.sliderInterval) // Change slide based on the interval
    },
    replaceCurrentPlaylist() {
      // Check if you have slides with type equal to youtube-video
      // If yes, just reload the page... is safer (because of all youtube players states)
      if (
        this.playlist.current.slides.filter(slide => slide.type === 'youtube-video') ||
        this.playlist.next.slides.filter(slide => slide.type === 'youtube-video')
      ) {
        // Reload the page to fetch the new screen data
        window.location.reload()
      } else {
        this.playlist.current = { ...this.playlist.next }
        this.playlist.next = { hash: null, slides: [] }

        console.log('Replaced current playlist with next:', this.playlist)

        // Load and start the playlist
        this.loadAndStartPlaylist()
      }
    },
    onVideoEnd(event) {
      this.currentSlideIndex = (this.currentSlideIndex + 1) % this.playlist.current.slides.length
      if (this.currentSlideIndex === 0 && this.playlist.next.hash) {
        this.replaceCurrentPlaylist()
      }
      // If video is the only slide, play it again
      if (this.playlist.current.slides.length === 1 && event.target) {
        if (event.target.playVideo) {
          event.target.playVideo()
          event.target.getIframe().style.opacity = 1
        } else {
          event.target.play()
        }
      }
    },

    // Use IndexedDB to store files locally
    openIDBDatabase() {
      if (!this.isIndexedDbSupported) {
        console.error('IndexedDB is not supported in this browser')
        return
      }

      // Create or open IndexedDB
      return openDB('motionscreenDB', 1, {
        upgrade(db) {
          if (!db.objectStoreNames.contains('files')) {
            db.createObjectStore('files')
          }
        }
      })
    },
    async getIDBFiles() {
      if (!this.isIndexedDbSupported) {
        console.error('IndexedDB is not supported in this browser')
        return []
      }
      // Get all files from IndexedDB
      const db = await this.openIDBDatabase()
      const tx = db.transaction('files', 'readonly')
      const store = tx.objectStore('files')
      return store.getAllKeys()
    },
    async cleanIDBFiles() {
      if (!this.isIndexedDbSupported) {
        console.error('IndexedDB is not supported in this browser')
        return
      }

      // Clean files from IndexedDB that are not in the new playlist current or next
      await this.openIDBDatabase().then(db => {
        const self = this
        const tx = db.transaction('files', 'readwrite')
        const store = tx.objectStore('files')
        store.getAllKeys().then(keys => {
          keys.forEach(key => {
            if (
              !self.playlist.current.slides.some(slide => `${slide.type}-${slide.url}` === key) &&
              !self.playlist.next.slides.some(slide => `${slide.type}-${slide.url}` === key)
            ) {
              console.log('Deleting file in IndexedDB:', key)
              store.delete(key)
            }
          })
        })
      })
    },
    async saveFileToIDB(fileUrl, fileType) {
      if (!this.isIndexedDbSupported) {
        console.error('IndexedDB is not supported in this browser')
        return fileUrl
      }

      try {
        // Open the database and check if the file already exists
        const db = await this.openIDBDatabase()
        const tx = db.transaction('files', 'readonly')
        const store = tx.objectStore('files')

        const fileData = await store.get(fileType + '-' + fileUrl)

        if (fileData) {
          // If the file is already in IndexedDB and the hash matches, display it
          console.log('File found in IndexedDB', fileUrl)
          return URL.createObjectURL(fileData.blob)
        } else {
          // If the file is not in IndexedDB, download and save it
          console.log('File not found in IndexedDB, download and store it', fileUrl)
          this.loading.downloadFiles = true
          const response = await fetch(fileUrl)
          if (!response.ok) throw new Error('Failed to fetch the file from API')

          const blob = await response.blob()

          const writeTx = db.transaction('files', 'readwrite')
          const writeStore = writeTx.objectStore('files')

          await writeStore.put({ blob }, fileType + '-' + fileUrl)
          await writeTx.done

          return URL.createObjectURL(blob)
        }
      } catch (error) {
        console.error('An error occurred:', error)
        this.error = error.message // Display the error to the user
      } finally {
        // Set loading to false when the process is complete
        this.loading.downloadFiles = false
      }
    },

    // Youtube Player
    createYouTubeVideoPlayer(videoId, slideIndex) {
      let self = this

      // Check for Youtube API
      if (!window.YT || !window.YT.Player) {
        console.error('Youtube API is not loaded')
        // Load it
        // eslint-disable-next-line no-undef
        const tag = document.createElement('script')
        tag.src = 'https://www.youtube.com/iframe_api'
        const firstScriptTag = document.getElementsByTagName('script')[0]
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
        // Try again
        setTimeout(() => {
          self.createYouTubeVideoPlayer(videoId, slideIndex)
        }, 500)
        return
      }

      console.log('Creating Youtube player', videoId, slideIndex)

      if (self.youtubeVideoPlayers[`slide-${slideIndex}`]) {
        console.log('Youtube player already exists', videoId, slideIndex)
        return
      }

      const player = new window.YT.Player(`youtube-player-${slideIndex}`, {
        events: {
          onReady: slideIndex === 0 ? self.onReadyStartYouTubeVideoPlayer : self.onReadyYouTubeVideoPlayer,
          onStateChange: self.onChangeYouTubeVideoPlayer
          // onError: self.YTerror,
        }
      })
      self.youtubeVideoPlayers[`slide-${slideIndex}`] = player
    },
    onChangeYouTubeVideoPlayer(event) {
      console.log('Youtube player state changed', event)
      if (event.data === window.YT.PlayerState.UNSTARTED) {
        console.log('Youtube video unstarted', event)
      }
      if (event.data === window.YT.PlayerState.PLAYING) {
        console.log('Youtube video started', event)
        event.target.getIframe().style.opacity = 1
      }
      if (event.data === window.YT.PlayerState.ENDED) {
        console.log('Youtube video ended', event)
        event.target.stopVideo()
        this.onVideoEnd(event)
      }
    },
    onReadyYouTubeVideoPlayer(event) {
      event.target.getIframe().style.opacity = 0
      console.log('Youtube player ready', event)
    },
    onReadyStartYouTubeVideoPlayer(event) {
      event.target.getIframe().style.opacity = 0
      console.log('Youtube player ready to start right way!', event)
      event.target.playVideo()
    }
  }
}
</script>

<style scoped>
.slide {
  opacity: 1;
  transition: opacity 0s;
  animation: fadeIn 0s ease-in-out;
}
.signage-image,
.signage-video {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.debug-panel {
  max-height: -webkit-fill-available;
}
.debug-panel pre {
  padding: 0;
  background: transparent;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
