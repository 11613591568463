<template>
  <view-employee-time-clock v-if="deviceMode === 'employee-time-clock'" />
  <view-agenda v-else-if="deviceMode === 'agenda'" />
  <view-signage v-else-if="deviceMode === 'signage'" />
  <view-event-checkin v-else-if="deviceMode === 'event-checkin'" />
  <view-configuration v-else />
</template>

<script>
import ViewConfiguration from './configuration/ViewConfiguration.vue'
import ViewEmployeeTimeClock from './ViewEmployeeTimeClock.vue'
import ViewAgenda from './ViewAgenda.vue'
import ViewSignage from './ViewSignage.vue'
import ViewEventCheckin from './ViewEventCheckin.vue'

export default {
  components: {
    ViewEmployeeTimeClock,
    ViewAgenda,
    ViewSignage,
    ViewConfiguration,
    ViewEventCheckin,
  },

  data() {
    return {
      deviceMode: null,
    }
  },

  mounted() {
    // Set device mode
    const urlParams = new URLSearchParams(window.location.search)
    this.deviceMode = urlParams.get('device-mode') || localStorage.getItem('device-mode') || 'configuration'
  },
}
</script>
