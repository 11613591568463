<template>
  <div
    v-if="isFuture || isHappening"
    class="flex py-4 gap-10"
    :class="{
      'opacity-50': !isFuture && !isHappening,
      'is-happening': isHappening,
      'is-future': isFuture
    }"
  >
    <div
      class="text-3xl font-bold"
      :class="{
        'opacity-50': !isHappening
      }"
    >
      {{ startTime }}
    </div>
    <div
      class="border-b flex-1 pb-8 gap-2 flex flex-col relative"
      :class="{
        'border-transparent': isHappening,
        'border-white/40': !isHappening
      }"
    >
      <div class="flex w-full">
        <div class="flex flex-col gap-5 flex-1">
          <div
            class="text-4xl font-extrabold"
            :class="{
              'text-green-agenda': isHappening
            }"
            v-html="title"
          ></div>
          <div v-if="location" class="text-white/70 flex gap-1.5 items-center">
            <div class="flex-1 text-2xl">{{ location }}</div>
          </div>
          <div v-if="showSpeakers" class="flex flex-col gap-5 mt-2">
            <div v-for="(speaker, s) in speakers" :key="'speaker' + s" class="flex gap-4">
              <div>
                <img v-if="speaker.photo" :src="speaker.photo" class="rounded-full w-14 h-14 overflow-hidden" />
                <svg
                  v-else
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-12 w-12 opacity-80 flex-shrink-0"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                  <path d="M12 10m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                  <path d="M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855" />
                </svg>
              </div>
              <div class="flex-1 flex justify-center flex-col">
                <div class="font-bold text-2xl">{{ speaker.name }}</div>
                <div class="text-xl text-white/50">
                  {{ speaker.job }}{{ speaker.job && speaker.institution ? ' - ' : '' }}{{ speaker.institution }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-3xl font-semibold opacity-50 w-28 text-right">{{ duration }}</div>
      </div>
      <div v-if="isHappening" class="progress w-full absolute bottom-0 left-0 right-0 h-1.5 bg-white/20">
        <div class="h-1.5" :style="{ width: progress + '%', backgroundColor: '#64EFAB' }"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventComponent',
  components: {},
  props: {
    event: {
      type: Object,
      required: true
    },
    primaryColor: {
      type: String,
      default: '#FFFFFF'
    },
    timeInfo: {
      type: Object,
      required: true
    }
  },
  computed: {
    progress() {
      if (this.isFuture) return 0
      if (this.isHappening) {
        let start = this.start.getTime()
        let end = this.end.getTime()
        let now = this.now.getTime()
        let progress = ((now - start) / (end - start)) * 100
        console.log(this.event, 'progress: ', progress, now - start, '/', end - start)
        return progress
      } else {
        return 100
      }
    },
    isFuture() {
      //Check if the event is currently happening
      let isFuture = this.now < this.start
      //console.log(this.event, 'isFuture: ', isFuture, this.start, '<', this.now)
      return isFuture
    },
    isHappening() {
      //Check if the event is currently happening
      let isNow = this.now >= this.start && this.now <= this.end
      //console.log(this.event, 'isNow: ',  isNow, this.start, this.now, this.end)
      return isNow
    },
    title() {
      return this.event.title
    },
    start() {
      return this.convertToLocalDate(this.event.start)
    },
    end() {
      return this.convertToLocalDate(this.event.end)
    },
    now() {
      //TODO: fake now - user real now
      //let fakeDay = '11'
      let timestamp =
        this.timeInfo.year +
        '-' +
        this.appendLeadingZeroes(this.timeInfo.month + 1) +
        '-' +
        this.appendLeadingZeroes(this.timeInfo.monthDay) +
        'T' +
        this.appendLeadingZeroes(this.timeInfo.hours) +
        ':' +
        this.appendLeadingZeroes(this.timeInfo.minutes) +
        ':00.000Z'
      console.log('TIMESTAMP: ', timestamp)
      return new Date(timestamp)
    },
    startTime() {
      //HH:MM
      return this.event.start.split(' ')[1].split(':')[0] + ':' + this.event.start.split(' ')[1].split(':')[1]
    },
    endTime() {
      //HH:MM
      return this.event.end.split(' ')[1].split(':')[0] + ':' + this.event.end.split(' ')[1].split(':')[1]
    },
    duration() {
      //Xh (X = number of hours) or XhYm (X = number of hours, Y = number of minutes)
      let duration = ''
      let start = this.event.start.split(' ')[1].split(':')
      let end = this.event.end.split(' ')[1].split(':')
      let hours = parseInt(end[0]) - parseInt(start[0])
      let minutes = parseInt(end[1]) - parseInt(start[1])
      if (minutes < 0) {
        hours--
        minutes += 60
      }
      if (hours > 0) duration += hours + 'h'
      if (minutes > 0) duration += minutes + 'm'
      return duration
    },
    url() {
      return this.event.url || null
    },
    location() {
      return this.event.local || null
    },
    speakers() {
      return this.event.speakers || []
    },
    showSpeakers() {
      return this.speakers.length > 0
    }
  },
  methods: {
    convertToLocalDate(date) {
      //Convert Lisbon time to local time
      let parts = date.split(' ')
      //console.log('input date: ', parts[0] + 'T' + parts[1] + '.000Z')
      return new Date(parts[0] + 'T' + parts[1] + '.000Z')
    },
    appendLeadingZeroes(n) {
      if (n <= 9) {
        return '0' + n
      }
      return n
    }
  }
}
</script>
