<template>
  <layout @admin-click="openAdmin()">
    <template #main>
      <div v-if="activated" class="flex-1 flex items-center justify-center h-full">
        <div class="bg-gray-800 bg-opacity-60 backdrop-blur rounded-3xl p-20 min-w-md">
          <!-- Show QRCode -->
          <div class="flex flex-col items-center justify-center gap-7">
            <div class="text-lg font-bold text-gray-500 uppercase">Marcação de Presença</div>
            <div v-if="sessionData" class="text-center">
              <div class="text-3xl font-bold text-gray-200 uppercase line-clamp-3">{{ sessionData.title }}</div>
              <div v-if="event?.title" class="text-white text-lg font-semibold mt-3">
                {{ event.title }}
              </div>
            </div>
            <div class="flex justify-center p-1 relative">
              <qrcode-vue
                v-if="qrCode"
                :key="qrKey"
                class="p-5 flex items-center justify-center bg-white rounded"
                :value="qrCode"
                :size="280"
                level="L"
              ></qrcode-vue>
            </div>
            <fw-heading class="flex items-center justify-center -mb-4">
              Scan with <fw-icon-uc-one class="w-16 h-16 p-2"></fw-icon-uc-one>
            </fw-heading>
          </div>
        </div>
      </div>
      <div v-else-if="sessions.length == 0">
        <div>
          <fw-heading size="h1" muted>Associar tablet a evento</fw-heading>
          <fw-label class="my-3">Código do evento</fw-label>
          <b-input
            type="password"
            v-model="eventActivationCode"
            size="is-medium"
            :disabled="loading"
            placeholder="Código do Evento"
          />
        </div>
        <div class="flex-1"></div>
        <div class="mt-5">
          <fw-button
            :type="canActivate ? 'primary' : 'disabled'"
            :loading="loading"
            size="lg"
            expanded
            :disabled="!canActivate"
            @click.native="loadSessions"
            >Seguinte</fw-button
          >
        </div>
      </div>
      <div v-else-if="sessions.length > 0">
        <div>
          <fw-heading size="h1" muted>Escolha a sessão</fw-heading>
          <div class="flex flex-col gap-2 mt-5">
            <div
              v-for="(session, s) in sessions"
              :key="s"
              class="bg-black p-5 text-white"
              @click="setupTablet(session)"
            >
              <div class="text-xl" :class="{ 'text-primary': session.key === sessionKey }">
                <span class="font-bold mr-1">{{ session.title }}</span
                ><span v-if="session.key === sessionKey" class="opacity-70">(ativo)</span>
              </div>
              <div>{{ session.start_date | formatDateTime }} - {{ session.end_date | formatDateTime }}</div>
            </div>
          </div>
          <div class="flex">
            <div class="h-20 w-20 text-3xl text-white flex items-center justify-center" @click="previous()">&lt;</div>
            <div class="flex-1"></div>
            <div class="h-20 w-20 text-3xl text-white flex items-center justify-center" @click="next()">&gt;</div>
          </div>
        </div>
      </div>
    </template>
  </layout>
</template>

<script>
import ServiceContent from '@/services/ServiceContent'
import Layout from '@/components/Layout.vue'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import QrcodeVue from 'qrcode.vue'
import config from '@/fw-modules/fw-core-vue/config.js'
export default {
  name: 'ViewEventCheckin',

  components: {
    QrcodeVue,
    Layout
  },

  data() {
    return {
      eventActivationCode: null,
      sessionKey: localStorage.getItem('session-key'),
      qrCode: null,
      qrKey: 0,
      appVersion: process.env.VUE_APP_VERSION,
      appName: process.env.VUE_APP_KEY,
      sessions: [],
      pagination: {
        current_page: 1
      },
      activated: false,
      timer: null,
      sessionData: null,
      loading: false
    }
  },

  computed: {
    deviceKey() {
      return localStorage.getItem('deviceKey')
    },
    eventCode() {
      return localStorage.getItem('event-code')
    },
    event() {
      return JSON.parse(localStorage.getItem('event'))
    },
    canActivate() {
      return this.eventActivationCode && this.eventActivationCode.length > 3
    }
  },

  created() {
    this.activated = this.eventCode && this.sessionKey
    this.start()
  },

  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },

  methods: {
    next() {
      if (this.pagination.current_page < this.pagination.total_pages) {
        this.pagination.current_page++
        this.loadSessions()
      }
    },
    previous() {
      if (this.pagination.current_page > 1) {
        this.pagination.current_page--
        this.loadSessions()
      }
    },
    openAdmin() {
      this.activated = false
      this.eventActivationCode = null
      this.sessions = []
    },
    start() {
      if (this.activated) {
        this.sessionData = JSON.parse(localStorage.getItem('session'))
        if (this.timer) {
          clearInterval(this.timer)
        }
        //generate QR code every 5 seconds
        this.generateQRcode()
        this.timer = setInterval(() => {
          this.generateQRcode()
        }, 10000)
      }
    },
    async loadSessions() {
      await utils.tryAndCatch(this, async () => {
        let result = await ServiceContent.getEventSessions(this.eventActivationCode, this.pagination.current_page)
        console.log('getEventSessions', result)
        this.sessions = result.sessions
        this.pagination = result.pagination

        // Load and save event data
        localStorage.setItem('event', JSON.stringify(result.event))
      })
    },
    async setupTablet(session) {
      if (this.sessionKey === session.key) {
        this.activated = true
        this.start()
        return
      }

      await utils.tryAndCatch(this, async () => {
        let result = await ServiceContent.registerEventsTablet(this.eventActivationCode, session.key)
        console.log('registerEventsTablet', result)
        if (result) {
          localStorage.setItem('event-code', this.eventActivationCode)
          localStorage.setItem('session', JSON.stringify(session))

          // Save session key
          localStorage.setItem('session-key', session.key)
          this.sessionKey = session.key

          this.activated = true
          this.start()
        }
      })
    },
    /**
     * Converting array buffer in hexadecimal string
     */
    bufToHex(buf) {
      const hashArray = Array.from(new Uint8Array(buf)) // convert buffer to byte array
      const msgHash = hashArray.map(b => b.toString(16).padStart(2, '0')).join('') // convert bytes to hex string
      // console.log('msgHash: ', msgHash)
      return msgHash
    },
    async generateQRcode() {
      //get device key
      let deviceKey = localStorage.getItem('deviceKey')
      let eventCode = localStorage.getItem('event-code')
      let sessionKey = this.sessionKey
      let privateKey = JSON.parse(localStorage.getItem('private-key'))
      //before 1 minut and after 3 minutes
      let validity_start = new Date()
      validity_start.setMinutes(validity_start.getMinutes() - 1)
      let validity_end = new Date()
      validity_end.setMinutes(validity_end.getMinutes() + 3)
      let data = {
        device_key: deviceKey,
        event_code: eventCode,
        session_key: sessionKey,
        validity_start: validity_start.toISOString(),
        validity_end: validity_end.toISOString()
      }
      let cryptoPrivateKey = await window.crypto.subtle.importKey(
        'jwk',
        privateKey,
        {
          name: 'ECDSA',
          namedCurve: 'P-384'
        },
        true,
        ['sign']
      )
      //TODO
      let signature = await this.signData(cryptoPrivateKey, data)
      /*let request = {
        data: data,
        signature: signature,
        action: 'checkin'
      }
      console.log('generateQRcode', request)*/

      let urlData =
        data.device_key +
        ';' +
        data.event_code +
        ';' +
        data.session_key +
        ';' +
        data.validity_start +
        ';' +
        data.validity_end

      let actions = this.sessionData.options.allowed_actions.map(action => (action == 'checkin' ? 'i' : 'o'))
      //base 64
      this.qrCode =
        config.appUrlOne +
        '/attend/' +
        encodeURIComponent(btoa(urlData)) +
        '/' +
        encodeURIComponent(signature) +
        '/' +
        actions.join('')
      this.qrKey++
    },
    async signData(privateKey, jsonData) {
      const encoder = new TextEncoder()
      const dataToSign =
        'd:' +
        jsonData.device_key +
        ',e:' +
        jsonData.event_code +
        ',s:' +
        jsonData.session_key +
        ',vs:' +
        jsonData.validity_start +
        ',ve:' +
        jsonData.validity_end
      console.log('dataToSign', dataToSign)
      const dataBuffer = encoder.encode(dataToSign)

      const signature = await window.crypto.subtle.sign(
        {
          name: 'ECDSA',
          hash: { name: 'SHA-256' }
        },
        privateKey,
        dataBuffer
      )
      //return this.bufToHex(signature)
      return this.arrayBufferToBase64(signature)
    },
    hexToBuffer(hex) {
      const bytes = []
      for (let c = 0; c < hex.length; c += 2) {
        bytes.push(parseInt(hex.substr(c, 2), 16))
      }
      return new Uint8Array(bytes)
    },
    arrayBufferToBase64(buffer) {
      const byteArray = new Uint8Array(buffer)
      let binaryString = ''
      byteArray.forEach(byte => {
        binaryString += String.fromCharCode(byte)
      })
      return btoa(binaryString) // Convert to Base64
    }
  }
}
</script>
